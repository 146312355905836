@import 'highlight.js/styles/github.css';
@import 'style.css';
@import 'fraktur.css';
@import 'big-cursors.css';

:root {
  --mono-font:
    Menlo,
    Monaco,
    "Ubuntu Mono",
    "Consolas",
    "Lucida Console",
    "Liberation Mono",
    "DejaVu Sans Mono",
    "Bitstream Vera Sans Mono",
    "Courier New",
    monospace,
    serif;

  --sans-font:
    "Helvetica Neue",
    Helvetica,
    Arial,
    "Lucida Grande",
    sans-serif;
}

html {
  background: #676767 url("./static/molecules.jpg") no-repeat bottom left;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

body {
  font-family: var(--mono-font);
  background-color: transparent;
}

::selection {
  background: hsla(60, 100%, 50%, 0.75); /* WebKit/Blink Browsers */
}

a {
  color: #fff;
  background-color: #000;
}

pre, code {
  background-color: hsla(0, 0%, 97%, 0.26);
  border-radius: 3px;
}

img {
  display: block;
  height: auto;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.markdown-body {
  background-color: hsla(360, 100%, 100%, 0.45);
  padding-top: 2em;
  padding-bottom: 1em;
  /* animation: fadein 1s; */
  transform: translateZ(-10px);
}

/* previously main styles */
.markdown-body {
  max-width: 50em;
  margin: 2em auto;
  padding: 0 1em;
  overflow: hidden;
  word-wrap: break-word;
}
.markdown-body > *:first-child { margin-top: 0 !important; }
.markdown-body > *:last-child { margin-bottom: 0 !important; }

footer {
  min-height: 1em;
}

.header-font {
  font-family: 'Fraktur BT', var(--mono-font), sans-serif;
  font-size: 6em;
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
  .header-font {
    font-size: 4.5em;
  }
}
